<template>
  <aside class="icon-toggle-options">
    <div v-for="(group, gKey) in groups" class="icon-toggle-options__group" :key="gKey">
      <icon-toggle-button v-for="(toggle, tKey) in group" :key="tKey" v-bind="toggle" />
    </div>
  </aside>
</template>

<script setup lang="ts">
import { computed } from "vue";

import IconToggleButton, { IconToggleButtonProps } from "@/components/buttons/icon-toggle-button.vue";

type Seperator = "seperator";

export type Option = IconToggleButtonProps | Seperator;

const props = defineProps<{ options: Option[] }>();

const groups = computed(() => {
  const _groups: IconToggleButtonProps[][] = [[]];
  for (const option of props.options) {
    if (option !== "seperator") {
      _groups[_groups.length - 1].push(option);
    } else {
      _groups.push([]);
    }
  }
  return _groups;
});
</script>

<style lang="scss" scoped>
.icon-toggle-options {
  display: flex;
  flex-flow: row;
  background: var(--menu-bg);
  border-radius: var(--app-radius-medium);
  border: 1px solid var(--black-20);
  overflow: auto;
  column-gap: 10px;
  &__group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
